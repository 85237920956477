import { FormEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import useSearchBarProducts from "../../api/products/useSearchBarProducts";
import { URLSearchParamsInit, useSearchParams } from "react-router-dom";
import useGetCustomers from "../../api/customers/useGetCustomers";
import { CustomerSelect } from "../commom/CustomerSelect";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import classNames from "classnames";
import { ItemSelect } from "../commom/ItemSelect";
import { EservicesStatus } from "../../constants/EservicesStatus";

type CartSearchBarProps = {
    setSearchParams: (nextInit: URLSearchParamsInit, navigateOptions?: {
        replace?: boolean | undefined;
        state?: any;
    } | undefined) => void,
    setCanFetch: React.Dispatch<React.SetStateAction<boolean>>
};

export type InvoicesSearchParams = {
    "filter[number]"?: string,
    "filter[item_code]"?: string,
    "filter[customer_id]"?: string,
    "filter[ordering_party]"?: string,
    "filter[customer_name]"?: string,
    "filter[client_reference]"?: string,
    "filter[status]"?: string,
    "filter[grossamount]"?: string,
    "filter[technical_sale]"?: string,
    "filter[min_date]"?: string,
    "filter[max_date]"?: string,
    "sort"?: string
};

type formData = {
    "filter[number]"?: string,
    "filter[item_code]"?: string,
    "filter[customer_id]"?: string,
    "filter[ordering_party]"?: string,
    "filter[customer_name]"?: string,
    "filter[client_reference]"?: string,
    "filter[status]"?: string,
    "filter[grossamount]"?: string,
    "filter[technical_sale]"?: string,
    "filter[min_date]"?: string,
    "filter[max_date]"?: string,
};

export default function InvoicesSearchBar({ setSearchParams, setCanFetch }: CartSearchBarProps) {
    const { t } = useTranslation();

    // refs
    const itemCodeSelect = useRef<any>();
    const customerSelect = useRef<any>();
    const orderingPartySelect = useRef<any>();

    // hooks
    const { roles } = useAuthContext();
    const { loadItemsOptions } = useSearchBarProducts();
    const { loadCustomersOptions } = useGetCustomers();
    const [searchParams] = useSearchParams();

    // state
    const [formData, setFormData] = useState<formData>({});
    const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
    const [selectedCommitment, setSelectedCommitment] = useState<any>(null);

    const handleInputsChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        if (e.target.value.length > 0) {
            setFormData((prevFormData: any) => {
                return { ...prevFormData, [name]: value };
            });
        } else {
            const newForm = { ...formData };
            delete newForm[name as keyof formData];
            setFormData(newForm);
        }
    };

    // ITEM CODE
    const handleItemSearchChange = (inputValue: any, action: any) => {
        if (action?.action === "select-option") {
            setFormData((prevFormData: any) => {
                return { ...prevFormData, [action?.name]: inputValue?.value?.code };
            });
        } else {
            const newForm = { ...formData };
            delete newForm[action?.name as keyof formData];
            setFormData(newForm);
        }
    };

    // CLIENT
    const handleClientSearchChange = (inputValue: any, action: any) => {
        setSelectedCustomer(inputValue);
        if (action?.action === "select-option") {
            setFormData((prevFormData: any) => {
                return { ...prevFormData, "filter[customer_id]": inputValue?.code };
            });
        } else {
            const newForm = { ...formData };
            delete newForm["filter[customer_id]"];
            setFormData(newForm);
        }
    };

    const handleOrderingPartySearchChange = (inputValue: any, action: any) => {
        setSelectedCommitment(inputValue);
        if (action?.action === "select-option") {
            setFormData((prevFormData: any) => {
                return { ...prevFormData, "filter[ordering_party]": inputValue?.code };
            });
        } else {
            const newForm = { ...formData };
            delete newForm["filter[ordering_party]"];
            setFormData(newForm);
        }
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        setCanFetch(true);
        setSearchParams(formData);
    };

    const resetForm = () => {
        itemCodeSelect.current.clearValue();
        customerSelect.current.clearValue();
        orderingPartySelect.current.clearValue();
        setFormData({});
        setSearchParams({});
        setCanFetch(true);
        setFormData({});
    };

    useEffect(() => {
        const tmpParams: any = {};

        searchParams.forEach((value: any, key: any) => {
            setCanFetch(true);
            tmpParams[key] = value;
        });

        setFormData(tmpParams);
    }, [searchParams]);

    return (
        <>
            <form onSubmit={handleSubmit}>
                <fieldset className="m-0">
                    <legend className="sr-only"><span>{t("Ricerca")}</span></legend>

                    <div className="space-y-4">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 whitespace-nowrap">
                            <CustomerSelect
                                label="Seleziona destinatario fattura"
                                ref={customerSelect}
                                loadCustomersOptions={loadCustomersOptions}
                                handleChange={handleClientSearchChange}
                                selectedCustomer={selectedCustomer}
                                setSelectedCustomer={setSelectedCustomer}
                            />
                            <CustomerSelect
                                label="Seleziona sottocliente"
                                ref={orderingPartySelect}
                                loadCustomersOptions={loadCustomersOptions}
                                handleChange={handleOrderingPartySearchChange}
                                selectedCustomer={selectedCommitment}
                                setSelectedCustomer={setSelectedCommitment}
                            />

                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 whitespace-nowrap">
                            <div>
                                <label>{t("Numero fattura")}</label>
                                <input
                                    value={formData["filter[number]"] ?? ""}
                                    type="text"
                                    name="filter[number]"
                                    onChange={e => handleInputsChange(e)}
                                    placeholder={t("inserisci numero fattura")}
                                />
                            </div>
                            <div>
                                <label>{t("Codice articolo")}</label>
                                <ItemSelect
                                    ref={itemCodeSelect}
                                    name="filter[item_code]"
                                    loadItemsOptions={loadItemsOptions}
                                    handleChange={handleItemSearchChange}
                                />
                            </div>
                        </div>

                        <div className={classNames("grid grid-cols-1 md:grid-cols-2 gap-4 whitespace-nowrap")}>
                            {/* CLIENT REFERENCE */}
                            <div>
                                <label>{t("Nota personalizzata")}</label>
                                <input
                                    value={formData["filter[client_reference]"] ?? ""}
                                    type="text"
                                    name="filter[client_reference]"
                                    onChange={e => handleInputsChange(e)}
                                    placeholder={t("Inserisci nota personalizzata")}
                                />
                            </div>
                            {!roles(["CLIENT", "SUB_CLIENT", "GROUP_LEADER"]) && <div>
                                <label>{t("Technical Salesman")}</label>
                                <input
                                    value={formData["filter[technical_sale]"] ?? ""}
                                    type="text"
                                    name="filter[technical_sale]"
                                    onChange={e => handleInputsChange(e)}
                                    placeholder={t("inserisci technical salesman")}
                                />
                            </div>}
                            <div className="flex gap-4">
                                <div className="w-1/2">
                                    <label>{t("Da")}</label>
                                    <input
                                        value={formData["filter[min_date]"] ?? ""}
                                        type="date"
                                        name="filter[min_date]"
                                        onChange={e => handleInputsChange(e)}
                                    />
                                </div>
                                <div className="w-1/2">
                                    <label>{t("A")}</label>
                                    <input
                                        value={formData["filter[max_date]"] ?? ""}
                                        type="date"
                                        name="filter[max_date]"
                                        onChange={e => handleInputsChange(e)}
                                    />
                                </div>
                            </div>
                            <div>
                                <label>{t("Totale fattura")}</label>
                                <input
                                    value={formData["filter[grossamount]"] ?? ""}
                                    type="number"
                                    name="filter[grossamount]"
                                    onChange={e => handleInputsChange(e)}
                                    placeholder={t("inserisci totale fattura")}
                                />
                            </div>
                            <div>
                                <label>{t("Stato fattura")}</label>
                                <select
                                    value={formData["filter[status]"] ?? ""}
                                    name="filter[status]"
                                    onChange={e => handleInputsChange(e)}
                                >
                                    <option value="">{t("Tutti")}</option>
                                    {
                                        EservicesStatus.invoice.map((eserviceStatus) => (
                                            <option key={eserviceStatus.status} value={eserviceStatus.status}>{t(eserviceStatus.description)}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="w-1/3 flex gap-x-2">
                            <Button variant={Button.variant.primary} size={Button.size.full}>{t("Cerca")}</Button>
                            <Button
                                variant={Button.variant.text}
                                className="text-xs py-0 flex-1 whitespace-nowrap"
                                icon={Button.icon.reset}
                                onClick={resetForm}
                            >
                                {t("reset ricerca")}
                            </Button>
                        </div>
                    </div>

                </fieldset>
            </form>
        </>
    );
}
